import { typeBlock, typeCoords, typeFace, typeHTHBuilding, typeHthComplexBuildingItem, typeHthNewComplexBuildingItem, typeHTHTerritory } from '@monorepo/models';

export const editInfoWindowsStyles = (): NodeJS.Timeout => setTimeout((): void => {
    const elements = document.getElementsByClassName('gm-ui-hover-effect') as HTMLCollectionOf<HTMLElement>;
    const v = document.getElementsByClassName('gm-style-iw-tc') as HTMLCollectionOf<HTMLElement>;
    const w = document.getElementsByClassName('gm-style-iw-a') as HTMLCollectionOf<HTMLElement>;
    const x = document.getElementsByClassName('gm-style-iw gm-style-iw-c') as HTMLCollectionOf<HTMLElement>;
    const y = document.getElementsByClassName('gm-style-iw-d') as HTMLCollectionOf<HTMLElement>;
    const z = document.getElementsByClassName('gm-style-iw-t') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elements.length; i++) {
        elements[i].classList.add('d-none');
    }
    for (let i = 0; i < v.length; i++) {
        v[i].classList.remove('gm-style-iw-tc');
    }
    for (let i = 0; i < x.length; i++) {
        x[i].style.backgroundColor = 'transparent';
        if (x[i] && x[i].classList.contains('gm-style-iw-c')) {
            x[i].classList.remove('gm-style-iw-c');
        }
    }
    for (let i = 0; i < y.length; i++) {
        y[i].style.backgroundColor = 'transparent';
        y[i].style.overflow = 'hidden';
        const a = y[i] as HTMLElement;
        let b;
        let c;
        if (a) b = a.firstChild as HTMLElement;
        if (b) c = b.firstChild as HTMLElement;
        if (c) {
            c.style.background = 'none';
            c.style.cursor = 'pointer';
        }
    }
    for (let i = 0; i < w.length; i++) {
        w[i].classList.remove('gm-style-iw-a');
    }
    for (let i = 0; i < z.length; i++) {
        z[i].classList.remove('gm-style-iw-t');
    }
}, 500);

const getCharacterForNumber = (numberToChange: number, hasNn: boolean = false): string => {  // String.fromCharCode(65 + apartmentNumber)}
    if (numberToChange === 1) return 'A';
    if (numberToChange === 2) return 'B';
    if (numberToChange === 3) return 'C';
    if (numberToChange === 4) return 'D';
    if (numberToChange === 5) return 'E';
    if (numberToChange === 6) return 'F';
    if (numberToChange === 7) return 'G';
    if (numberToChange === 8) return 'H';
    if (numberToChange === 9) return 'I';
    if (numberToChange === 10) return 'J';
    if (numberToChange === 11) return 'K';
    if (numberToChange === 12) return 'L';
    if (numberToChange === 13) return 'M';
    if (numberToChange === 14) return 'N';
    if (numberToChange === 15) return hasNn ? 'Ñ' : 'O';
    if (numberToChange === 16) return hasNn ? 'O' : 'P';
    if (numberToChange === 17) return hasNn ? 'P' : 'Q';
    if (numberToChange === 18) return hasNn ? 'Q' : 'R';
    if (numberToChange === 19) return hasNn ? 'R' : 'S';
    if (numberToChange === 20) return hasNn ? 'S' : 'T';
    if (numberToChange === 21) return hasNn ? 'T' : 'U';
    if (numberToChange === 22) return hasNn ? 'U' : 'V';
    if (numberToChange === 23) return hasNn ? 'V' : 'W';
    if (numberToChange === 24) return hasNn ? 'W' : 'X';
    if (numberToChange === 25) return hasNn ? 'X' : 'Y';
    if (numberToChange === 26) return hasNn ? 'Y' : 'Z';
    if (numberToChange === 27) return hasNn ? '?' : 'Z';
    return '?';
}

export const getFreeHouseholds = (building: typeHTHBuilding): number => {
    if (!building.households?.length) return 0;
    const rest = building.households.filter(x => !x.isChecked).length;
    if (!!building.manager) {
        return !!building.manager.isChecked ? rest : rest + 1;
    }
    return rest;
}

export const getHouseholdDoorBell = (doorNumber: number, index: number, index1: number, hasContinuousNumbers: boolean, hasCharacters: boolean, numberPerLevel: number, hasNn: boolean): string => {
    if (hasContinuousNumbers) {
        if (hasCharacters) return getCharacterForNumber(index*numberPerLevel + index1 + 1, hasNn);
        return (index*numberPerLevel + index1 + 1).toString();
    } else {
        if (hasCharacters) return getCharacterForNumber(doorNumber, hasNn);
        return doorNumber.toString();
    }
}

export const getHthComplexBuilding = (currentBuilding: typeHTHBuilding): typeHthComplexBuildingItem[][] => (
    Array.from({ length: currentBuilding.numberPerLevel }, (_, door) =>
        Array.from({ length: currentBuilding.numberOfLevels }, (_, level) => ({
            id: `item-${level + door}-${new Date().getTime()}`,
            household: currentBuilding.households.find(h => h.level === level && h.doorNumber === door) || null
        })
    ))
);

export const getHthNewComplexBuilding = (numberOfLevels: number, numberPerLevel: number): typeHthNewComplexBuildingItem[][] => (
    Array.from({ length: numberPerLevel }, (_, door) => (
        Array.from({ length: numberOfLevels }, (_, level) => ({
            id: `item-${level + door}-${new Date().getTime()}`,
            label: `Fila ${level + 1} Columna ${door + 1}`
        }))
    ))
);

export const getMiddlePointOfCoordinates = (coordinates1: typeCoords, coordinates2: typeCoords): typeCoords => {
    const middlePointCoordinates = {
        lat: (coordinates1.lat + coordinates2.lat) / 2,
        lng: (coordinates1.lng + coordinates2.lng) / 2
    }
    return middlePointCoordinates;
}

export const getPolygonCoordinates = (numberOfCoordinate: number, numberOfPolygon: number, blockCoordinates?: typeCoords[]) => {
    const getCenter = (coordinates: typeCoords[]): typeCoords => {
        let sumLat = 0;
        let sumLng = 0;
        for (const coordinate of coordinates) {
            sumLat += coordinate.lat;
            sumLng += coordinate.lng;
        }
        const numberOfCoordinates = coordinates.length;
        const lat = sumLat / numberOfCoordinates;
        const lng = sumLng / numberOfCoordinates;
        return { lat, lng }
    }
    const coordinate: typeCoords = {
        lat: 0,
        lng: 0
    }
    if (!blockCoordinates) return coordinate;
    const center: typeCoords = getCenter(blockCoordinates);
    if (numberOfCoordinate === 3) return center;
    if (numberOfPolygon === 1) {
        if (numberOfCoordinate === 1) {
            coordinate.lat = blockCoordinates[0].lat;
            coordinate.lng = blockCoordinates[0].lng;
        } else if (numberOfCoordinate === 2) {
            coordinate.lat = blockCoordinates[1].lat;
            coordinate.lng = blockCoordinates[1].lng;
        }
    } else if (numberOfPolygon === 2) {
        if (numberOfCoordinate === 1) {
            coordinate.lat = blockCoordinates[1].lat;
            coordinate.lng = blockCoordinates[1].lng;
        } else if (numberOfCoordinate === 2) {
            coordinate.lat = blockCoordinates[2].lat;
            coordinate.lng = blockCoordinates[2].lng;
        }
    } else if (numberOfPolygon === 3) {
        if (numberOfCoordinate === 1) {
            coordinate.lat = blockCoordinates[2].lat;
            coordinate.lng = blockCoordinates[2].lng;
        } else if (numberOfCoordinate === 2) {
            coordinate.lat = blockCoordinates[3].lat;
            coordinate.lng = blockCoordinates[3].lng;
        }
    } else if (numberOfPolygon === 4) {
        if (numberOfCoordinate === 1) {
            coordinate.lat = blockCoordinates[3].lat;
            coordinate.lng = blockCoordinates[3].lng;
        } else if (numberOfCoordinate === 2) {
            coordinate.lat = blockCoordinates[0].lat;
            coordinate.lng = blockCoordinates[0].lng;
        }
    }
    return coordinate;
}

export const getStreetsByHTHTerritory = (hthTerritory: typeHTHTerritory): string[] => {
    if (!hthTerritory || !hthTerritory.map || !hthTerritory.map.polygons) return [];
    const streets: string[] = [];
    hthTerritory.map.polygons.forEach(x => {
        if (x.street && !streets.includes(x.street)) streets.push(x.street);
    })
    return streets;
}

export const maskTheBlock = (block: typeBlock, usingLettersForBlock: boolean): typeBlock | string => (
    usingLettersForBlock ? getCharacterForNumber(parseInt(block)) : block
);

export const maskTheFace = (face: typeFace, usingLettersForBlock: boolean): typeFace | number => {
    if (usingLettersForBlock) {
        if (face === 'A') return 1;
        if (face === 'B') return 2;
        if (face === 'C') return 3;
        if (face === 'D') return 4;
        if (face === 'E') return 5;
        if (face === 'F') return 6;
        // if (face === 'G') return 7;
        // if (face === 'H') return 8;
        // if (face === 'I') return 9;
        // if (face === 'J') return 10;
        // if (face === 'K') return 11;
        return 0;
    } else {
        return face;
    }
}

export const sortCoordinatesClockwise = (coordinates: typeCoords[]) => {
    let closestPoint = coordinates[0];
    let shortestDistance = Math.abs(closestPoint.lat) + Math.abs(closestPoint.lng);
    for (const point of coordinates) {
        const distance = Math.abs(point.lat) + Math.abs(point.lng);
        if (distance < shortestDistance) {
            closestPoint = point;
            shortestDistance = distance;
        }
    }
    const sortedPoints = coordinates
        .filter((point: typeCoords) => point !== closestPoint)
        .sort((a: typeCoords, b: typeCoords) => {
            const angleA = Math.atan2(a.lat - closestPoint.lat, a.lng - closestPoint.lng);
            const angleB = Math.atan2(b.lat - closestPoint.lat, b.lng - closestPoint.lng);
            return angleB - angleA;
        });
    sortedPoints.unshift(closestPoint);
    return sortedPoints;
}
