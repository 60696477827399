import { appName, pointer } from '../app-config';
import { getCurrentLocalDate } from '@monorepo/helpers';
import { getHeaders } from '.';
import { getTokenFromLSService } from './localStorageServices';
import { typeResponseData } from '@monorepo/models';
import JSZip from 'jszip';

const base = pointer.config;

export const downloadDbBackupService = async (withFiles: boolean, all: boolean): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/db-backup?all=${all}&files=${withFiles}`, {
            method: 'GET',
            headers: getHeaders()
        });
        const data: typeResponseData = await response.json();
        if (!data) return false;
        const dbBackups = all ? data.dbBackups : [data.dbBackup];
        if (!dbBackups) return false;
        const zip = new JSZip();
        const name = `Backup_${getCurrentLocalDate()}_${appName.replace(' ', '')}${dbBackups.length > 1 ? '_Completo' : ''}`;
        for (let i = 0; i < dbBackups.length; i++) {
            const dbBackup = dbBackups[i];
            if (!dbBackup?.config) continue;
            if (dbBackup.board) {
                zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}BoardItems.json`, JSON.stringify(dbBackup.board, null, 4));
            }
            if (dbBackup.cartDoc) {
                zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}CartDoc.json`, JSON.stringify(dbBackup.cartDoc, null, 4));
            }
            if (dbBackup.config) {
                zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}Config.json`, JSON.stringify(dbBackup.config, null, 4));
            }
            if (dbBackup.houseToHouseTerritories) {
                zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}HouseToHouseTerritories.json`, JSON.stringify(dbBackup.houseToHouseTerritories, null, 4));
            }
            if (dbBackup.logs) {
                zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}Logs.json`, JSON.stringify(dbBackup.logs, null, 4));
            }
            if (dbBackup.telephonicTerritories) {
                zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}TelephonicTerritories.json`, JSON.stringify(dbBackup.telephonicTerritories, null, 4));
            }
            if (dbBackup.users) {
                zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}Users.json`, JSON.stringify(dbBackup.users, null, 4));
        }
    }
        zip.generateAsync({ type: 'blob' }).then((content) => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(content);
            a.download = `${name}.zip`;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const sendInvitationForNewUserService = async (email: string, newCongregation: boolean = false): Promise<typeResponseData|null> => {
    if (!getTokenFromLSService()) return null;
    try {
        const response = await fetch(`${base}/invite`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({ email, newCongregation })
        });
        const data: typeResponseData = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const setCongregationNameService = async (name: string): Promise<boolean> => {
    if (!getTokenFromLSService() || !name) return false;
    try {
        const response = await fetch(base, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ name })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const setDisableCloseHthFacesService = async (disableCloseHthFaces: boolean): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(base, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ disableCloseHthFaces })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const setDisableHthBuildingsForUnassignedUsersService = async (disableHthBuildingsForUnassignedUsers: boolean): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(base, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ disableHthBuildingsForUnassignedUsers })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const setDisableHthFaceObservatiosService = async (disableHthFaceObservations: boolean): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(base, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ disableHthFaceObservations })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const setDisableEditMapsService = async (disableEditHthMaps: boolean): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(base, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ disableEditHthMaps })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const setUsingLettesForBlocksService = async (useLettersForBlocks: boolean): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(base, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ useLettersForBlocks })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}
