import { Dispatch, FC, SetStateAction } from 'react';
import { maskTheBlock } from '../../../services';
import { typeBlock, typeHTHTerritory } from '@monorepo/models';

type propsType = {
    filter: typeBlock[];
    territoryHTH: typeHTHTerritory;
    setFilter: Dispatch<SetStateAction<typeBlock[]>>;
    usingLettersForBlocks: boolean;
}

export const HTHBuildingsFilter: FC<propsType> = ({ filter, setFilter, territoryHTH, usingLettersForBlocks }) => {
    return (
    <>
        <div className={'d-flex justify-content-center flex-wrap mt-4 mb-3'}>
            {[...new Set(territoryHTH.map.polygons.map(p => p.block))].map(b =>
                <div key={b} className={'m-2'}>
                    <div className={'col card btn-general-blue c-pointer py-1 mx-auto'}
                        style={{ width: '250px' }}
                        onClick={() => filter.includes(b) ?
                            setFilter(x => x.filter(y => y !== b))
                            :
                            setFilter(x => [...x, b])
                        }
                    >
                        <div className={'d-flex justify-content-center'}>
                            <input type='checkbox'
                                className={'form-check-input checkbox-xs checkbox-black me-2'}
                                checked={filter.includes(b)}
                                onChange={() => {}}
                            />
                            <label className={'h5 ms-2 c-pointer'} style={{ marginTop: '2px' }}>
                                Manzana {maskTheBlock(b, usingLettersForBlocks)}
                            </label>
                        </div>
                    </div>
                    <div className={'bg-light small text-center'}>
                        {territoryHTH.map.polygons.filter(p => p.block === b)?.map(p => p.street).join(', ')}
                    </div>
                </div>
            )}
        </div>
    </>)
}
