import { Dispatch, FC, SetStateAction, useState } from 'react';
import { typeHTHBuilding, typeHTHTerritory } from '@monorepo/models';
import { useGetIsDarkMode } from '../../../../custom-hooks';

type propsType = {
    hthTerritories: typeHTHTerritory[];
}

export const HTHBuildings: FC<propsType> = ({ hthTerritories }) => {
    const [territoryToShow, setTerritoryToShow] = useState<typeHTHTerritory | null>(null);
    const isDarkMode = useGetIsDarkMode();

    return (
        <div className={'mt-4'}>

            <h3 className={`mt-4 ${isDarkMode ? 'text-white' : ''}`}>
                Cantidad total de edificios: {hthTerritories.map(t => t.map.polygons.map(x => x.buildings?.length || 0).reduce((a, n) => a + n, 0)).reduce((a, n) => a + n, 0)}
            </h3>

            {hthTerritories.sort((a, b) => parseInt(a.territoryNumber) - parseInt(b.territoryNumber)).map(t =>
                <Territory key={t.territoryNumber}
                    setTerritoryToShow={setTerritoryToShow}
                    t={t}
                    territoryToShow={territoryToShow}
                />
            )}
        </div>
    )
}

type propsType1 = {
    setTerritoryToShow: Dispatch<SetStateAction<typeHTHTerritory | null>>;
    t: typeHTHTerritory;
    territoryToShow: typeHTHTerritory | null;
}

const Territory: FC<propsType1> = ({ setTerritoryToShow, t, territoryToShow }) => {
    const isDarkMode = useGetIsDarkMode();

    const getLastTime = (building: typeHTHBuilding): string => {
        const maxd = Math.max(...building.households.filter(h => !!h.onDates?.length).map(h => Math.max(...h.onDates ?? [1])));
        return maxd !== -Infinity ? new Date(maxd).toLocaleDateString() : '-';
    }

    return (
        <div className={'my-5'}>
            <div className={`card ${isDarkMode ? 'text-white' : ''}`}>
                <div className={`card-header ${isDarkMode ? 'bg-success' : ''} d-flex justify-content-between`}>
                    <div className={'fw-bold'}>
                        TERRITORIO {t.territoryNumber} - Edificios: {t.map.polygons.map(x => x.buildings?.length || 0).reduce((a, n) => a + n, 0)}
                    </div>
                    <button className={`btn btn-general-${t.territoryNumber === territoryToShow?.territoryNumber ? 'red' : 'blue'} btn-sm p-1`}
                        style={{ width: '90px' }}
                        onClick={() => setTerritoryToShow(x => x?.territoryNumber === t.territoryNumber ? null : t)}
                    >
                        {t.territoryNumber === territoryToShow?.territoryNumber ? 'Ocultar' : 'Ver todo'}
                    </button>
                </div>
                <div className={`card-body ${isDarkMode ? 'bg-dark' : ''}`}>
                    {territoryToShow?.territoryNumber === t.territoryNumber &&
                        <>
                        {t.map.polygons.sort((a, b) => parseInt(a.block) - parseInt(b.block) || a.face.localeCompare(b.face)).map(polygon =>
                            <div key={polygon.id}>
                                {!!polygon.buildings?.length && <>
                                    {(polygon.face === 'A'
                                        || (polygon.face === 'B' && !t.map.polygons.find(x => x.block === polygon.block && x.face === 'A' && !!x.buildings?.length))
                                        || (polygon.face === 'C' && !t.map.polygons.find(x => x.block === polygon.block && ['A', 'B'].includes(x.face) && !!x.buildings?.length))
                                        || (polygon.face === 'D' && !t.map.polygons.find(x => x.block === polygon.block && ['A', 'B', 'C'].includes(x.face) && !!x.buildings?.length))
                                    ) &&
                                        <h4 className='mt-3'>
                                            MANZANA {polygon.block} - Edificios: {polygon.buildings.length}
                                        </h4>
                                    }
                                    {polygon.buildings?.sort((a, b) => a.streetNumber - b.streetNumber).map(building =>
                                        <div key={`${polygon.street}_${building.streetNumber}`}>
                                            <span className={'h5'}>{polygon.street} {building.streetNumber}{building.streetNumber2 ? `/${building.streetNumber2}` : ''}{building.streetNumber3 ? `/${building.streetNumber3}` : ''}: </span>
                                            <span>({building.households.length} timbres) Ult. vez: {getLastTime(building)}</span>
                                        </div>
                                    )}
                                </>}
                            </div>
                        )}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
