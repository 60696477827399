import { getHeaders } from './helpers/_helpers';
import { getTokenFromLSService } from './localStorageServices';
import { pointer } from 'src/app-config';
import { typeMeetingsDoc, typeMidweekMeeting, typeResponseData } from '@monorepo/models';

class MeetingServices {
    private _base = pointer.meeting;
    //
    Get = async (): Promise<typeMeetingsDoc | null> => {
        try {
            if (!getTokenFromLSService()) throw new Error('No autenticado');
            const response = await fetch(this._base, {
                method: 'GET',
                headers: getHeaders()
            });
            const data = await response.json() as typeResponseData;
            return data?.meetingDoc ?? null;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    GetWolData = async (weekNumber: number, year: number): Promise<typeMidweekMeeting | null> => {
        try {
            if (!getTokenFromLSService()) throw new Error('No autenticado');
            const response = await fetch(`${this._base}/wol?weekNumber=${weekNumber}&year=${year}`, {
                method: 'GET',
                headers: getHeaders()
            });
            const data = await response.json() as typeResponseData;
            return data?.wolData ?? null;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
}

export const meetingServices = new MeetingServices();
