import { getWeekNumber } from '../../../services';
import { H2, HorizontalSelector } from '../../_commons';
import { useState } from 'react';

export const AdminsPublicMeetings = () => {
    const [weekNumber, setWeekNumber] = useState(getWeekNumber(new Date()));

    return (
        <>
        <H2 title={'REUNIONES PÚBLICAS'} />

        <HorizontalSelector
            label={`Semana ${weekNumber}`}
            setNext={() => {}}
            setPrevious={() => {}}
        />

        <div>
            <h5>En desarrollo</h5>
        </div>
        </>
    )
}
